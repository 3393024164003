import axios from "axios";
import { xlog, setCookie, getToken, getRefreshToken, isLogin, logout } from "../../libs/utils";
import CONSTANT from "../../constants";
import apis from "./index";

export default async (req) => {
    const TAG = "[apis/postLogin]";
    const url = `${process.env.REACT_APP_API_URL}${CONSTANT.URI.COMMON.UPLOAD}/banner`;
    console.log(TAG, `url: ${url} req: `, req);

    const header = { "content-type": "multipart/form-data", Authorization: null };
    const token = getToken();
    if (token) {
        header.Authorization = `Bearer ${token} `;
    }

    let rs = null;
    try {
        rs = await axios({
            method: "post",
            url: url,
            headers: header,
            // headers: { Authorization: `Bearer ${getToken()} ` },
            data: req,
        });
    } catch (err) {
        console.log(TAG, "error", err);
        // rs = error;
    }
    if (rs.data?.result) {
        rs = rs.data?.result;
        if (rs.status?.code === '4100') { //	access token is expired
            const rt = getRefreshToken();
            const rs2 = await apis.putLogin({ refreshToken: rt });
            xlog(TAG, "r2", rs2);
            if (rs.data?.accessToken) {
                setCookie(CONSTANT.AUTH_TOKEN, rs.data.accessToken);
                setCookie(CONSTANT.AUTH_REFRESH_TOKEN, rs.data.refreshToken);
            } else {
                logout();
            }
        } else if (rs.status?.code === '4102' || rs.status?.code === '4002') { // 	refresh token is expired
            logout();
        }
    }
    return rs;
};
