import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, useRoutes } from "react-router-dom";

import './App.css';
import styled from "@emotion/styled";
import store from "./redux/store";
import { routes } from "./libs/routes";
import GNB from "./components/gnb";
import LNB from "./components/lnb";
import SNB from "./components/snb";

const App = () => {
    return useRoutes(routes);
};

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                < MainContainer >
                    <GNB></GNB>
                    <BodyContainer>
                        <SNB></SNB>
                        <ContentContainer>
                            <LNB></LNB>
                            <App />
                        </ContentContainer>
                    </BodyContainer>
                </MainContainer >
            </BrowserRouter>
        </Provider>
    );
};

export default AppWrapper;

const MainContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
align-items: stretch;
`;
const BodyContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
height: 100%;
align-items: stretch;
`;
const ContentContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
align-items: stretch;
`;