import axios from "axios";
import { xlog, setCookie, getToken, getRefreshToken, isLogin, logout } from "../../libs/utils";
import CONSTANT from "../../constants";
import apis from "./index";

/**
 * @param listType 3: 배너관리-히어로배너, 4: 배너관리-메인서브배너
 * @param isActive 1: 게시중배너, 2: 지난배너
 * @param pageNo
 */
export default async (req) => {
    const TAG = "[apis/getBannerList]";
    let pageNo = req.query?.pageNo;
    if (!pageNo) {
        pageNo = 1;
    }
    let url = `${process.env.REACT_APP_API_URL}${CONSTANT.URI.ADMIN.BANNER_LIST}?listType=${req.query.listType}&isActive=${req.query.isActive}&pageNo=${pageNo}`;
    console.log(TAG, `url: ${url} token:${getToken()}`);

    let rs = null;
    try {
        rs = await axios({
            method: "get",
            url: url,
            headers: { Authorization: `Bearer ${getToken()}` },
        });
    } catch (err) {
        console.log(TAG, "error", err);
    }
    if (rs.data?.result) {
        rs = rs.data?.result;
        if (rs.status?.code === '4100') { //	access token is expired
            const rt = getRefreshToken();
            const rs2 = await apis.putLogin({ refreshToken: rt });
            xlog(TAG, "r2", rs2);
            if (rs.data?.accessToken) {
                setCookie(CONSTANT.AUTH_TOKEN, rs.data.accessToken);
                setCookie(CONSTANT.AUTH_REFRESH_TOKEN, rs.data.refreshToken);
            } else {
                logout();
            }
        } else if (rs.status?.code === '4102' || rs.status?.code === '4002') { // 	refresh token is expired
            logout();
        }
    }
    return rs;
};
