import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import { xlog, setCookie, isLogin, getCookie } from "../libs/utils";
import CONSTANT from "../constants";

import LoginComponent from "@components/login";
import api from "./apis";
import { Cookies } from "react-cookie";

const useLogin = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return { global, dispatch, navigate };
};

const Login = () => {
    const TAG = "Login";
    const { global, dispatch, navigate } = useLogin();

    useEffect(() => {
        if (global.isLogin) {
            navigate("/shop");
        }
    }, []);
    const login = async (adminId, adminPwd) => {
        try {
            const rs = await api.postLogin({ adminId, adminPwd });
            xlog(TAG, "[login]rs", rs);
            if (rs.data && rs.data.accessToken) {
                xlog(TAG, "[login]success", rs.data.accessToken);

                setCookie(CONSTANT.AUTH_TOKEN, rs.data.accessToken);
                setCookie(CONSTANT.AUTH_REFRESH_TOKEN, rs.data.refreshToken);

                navigate("/");
            }
        } catch (err) {
            xlog(TAG, "[login]err", err);
        }
    };

    return (
        <LoginComponent login={login} />
    );

};
export default Login;