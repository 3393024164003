import axios from "axios";
import { xlog, setCookie, getToken, getRefreshToken, isLogin, logout } from "../../libs/utils";
import CONSTANT from "../../constants";
import apis from "./index";

/**
 * @param   locationType    1-메인히어로, 2-메인서브
 * @param   mainImgPath
 * @param   subImgPath
 * @param   startDate
 * @param   endDate
 * req
 {
  "locationType": "1",
  "mainImgPath": "/files/img1.png",
  "subImgPath": "/files/img1.png",
  "startDate": "2022-03-10",
  "endDate": "2022-03-10"
}
 */
export default async (req) => {
    const TAG = "[apis/postBanner]";
    const url = process.env.REACT_APP_API_URL + CONSTANT.URI.ADMIN.BANNER;
    console.log(TAG, `url: ${url} req:`, req);

    let rs = null;
    try {
        rs = await axios({
            method: "post",
            url: url,
            headers: { Authorization: `Bearer ${getToken()}` },
            data: req,
        });
    } catch (err) {
        console.log(TAG, "error", err);
        // rs = error;
    }
    if (rs.data?.result) {
        rs = rs.data?.result;
        if (rs.status?.code === '4100') { //	access token is expired
            const rt = getRefreshToken();
            const rs2 = await apis.putLogin({ refreshToken: rt });
            xlog(TAG, "r2", rs2);
            if (rs.data?.accessToken) {
                setCookie(CONSTANT.AUTH_TOKEN, rs.data.accessToken);
                setCookie(CONSTANT.AUTH_REFRESH_TOKEN, rs.data.refreshToken);
            } else {
                logout();
            }
        } else if (rs.status?.code === '4102' || rs.status?.code === '4002') { // 	refresh token is expired
            logout();
        }
    }
    return rs;
};
