import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import { xlog } from "../libs/utils";
import apis from "./apis";

import BannerComponent from "@components/banner";

const useBanner = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [bannerList, setBannerList] = useState([]);
    const [getBannerListRs, setGetBannerListRs] = useState({ bannerList: [], totalCnt: 0 });
    return { global, dispatch, navigate, bannerList, setBannerList, getBannerListRs, setGetBannerListRs };
};

const Banner = () => {
    const TAG = "Banner";
    const { global, dispatch, navigate, bannerList, setBannerList, getBannerListRs, setGetBannerListRs } = useBanner();

    /**
     * 배너관리
     * @param listType 3: 배너관리-히어로배너, 4: 배너관리-메인서브배너
     * @param isActive 1: 게시중배너, 2: 지난배너
     * @param pageNo
     */
    const getBannerList = async (listType = 3, isActive = 1, pageNo = 1) => {
        const req: any = { query: { listType: listType, isActive: isActive, pageNo: pageNo } };
        const rs = await apis.getBannerList(req);
        if (rs && rs.data && rs.data.bannerList) {
            xlog(TAG, "rs", rs);
            setGetBannerListRs(rs.data);
            // setBannerList(rs.data.bannerList);
        } else {
            // setBannerList([]);
            setGetBannerListRs({ bannerList: [], totalCnt: 0 });
        }
    };

    useEffect(() => {
        getBannerList();
    }, []);

    return (
        <BannerComponent getBannerList={getBannerList} bannerList={getBannerListRs?.bannerList} totalCnt={getBannerListRs?.totalCnt} />
    );

};
export default Banner;