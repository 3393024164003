import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
// import { css } from '@emotion/react';
// import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import { xlog } from "../libs/utils";
import apis from "./apis";

import ShopComponent from "@components/shop";
import { RED_100 } from "@styles/color";

const useShop = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [storeList, setStoreList] = useState([]);
    const [getStoreListRs, setGetStoreListRs] = useState({ storeList: [], totalCnt: 0 });
    return { global, dispatch, navigate, storeList, setStoreList, getStoreListRs, setGetStoreListRs };
};

const Shop = () => {
    const TAG = "Shop";
    const pageCnt = 15;
    const { global, dispatch, navigate, storeList, setStoreList, getStoreListRs, setGetStoreListRs } = useShop();

    /**
     * 
     * @param listType 5-매장관리>가입승인, 6-매장관리>매장회원리스트
     * @param orderType listType=5 일때, orderType(1-최신순, 2-이름순, 3-특가메뉴건순, 4-찜사용률순) 
     *                  | listType=6 일때, orderType(1-최신순, 2-이름순, 3-찜예약건수순, 4-찜사용건수순)
     * @param status listType=5 일때 필수, 1-승인대기, 2-승인완료, 3-승인거부
     */
    const getStoreList = async (listType = 5, orderType = 1, status = 1, pageNo = 1, pageCnt = 10, searchValue?) => {
        const req: any = { query: { listType: listType, orderType: orderType, status: status, pageNo: pageNo, pageCnt: pageCnt } };
        if (searchValue) {
            req.query.searchValue = searchValue;
        }
        const rs = await apis.getStoreList(req);
        if (rs && rs.data && rs.data.storeList) {
            xlog(TAG, "rs", rs);
            setGetStoreListRs(rs.data);
            // setStoreList(rs.data.storeList);
        } else {
            setGetStoreListRs({ storeList: [], totalCnt: 0 });
            // setStoreList([]);
        }
    };

    /**
     * 
     * @param storeNo 
     * @param status 1 - 승인대기, 2 - 승인완료, 3 - 승인거부
     * @param refuseMemo 반려 사유
     */
    const putStoreStatus = async (storeNo, status, refuseMemo = '') => {
        const req: any = { storeNo: storeNo, status: status, refuseMemo: refuseMemo };
        xlog(TAG, "[putStoreStatus]", req);
        const rs = await apis.putStoreStatus(req);
        getStoreList();
    };

    useEffect(() => {
        xlog(TAG, "[getStoreList]");
        getStoreList();
    }, []);

    return (
        <ShopComponent putStoreStatus={putStoreStatus} getStoreList={getStoreList} storeList={getStoreListRs?.storeList} totalCnt={getStoreListRs?.totalCnt} pageCnt={pageCnt}/>
    );

};
export default Shop;