import { createReducer, createAction, PayloadAction } from "@reduxjs/toolkit";
import { nsCommon } from 'type-common';

// action types
export const SET_IS_ADMIN = "global/SET_IS_ADMIN";
export const SET_IS_LOGIN = "global/SET_IS_LOGIN";

// actions
export const setIsAdmin = createAction<boolean>(SET_IS_ADMIN);
export const setIsLogin = createAction<boolean>(SET_IS_LOGIN);

// state
const initState: nsCommon.IGlobalState = {
    isAdmin: false,
    isLogin: false,
};

// reducer
const global = createReducer(initState, {
    [SET_IS_ADMIN]: (state, action: PayloadAction<boolean>) => {
        state.isAdmin = action.payload;
    },
    [SET_IS_LOGIN]: (state, action: PayloadAction<boolean>) => {
        state.isLogin = action.payload;
    },
});

export default global;