import axios from "axios";
import { xlog, setCookie, getToken, getRefreshToken, isLogin, logout } from "../../libs/utils";
import CONSTANT from "../../constants";
import apis from "./index";

/**
 * @param req.query.orderType   1-최신순, 2-이름순, 3-찜예약건수순, 4-찜사용건수순
 */
export default async (req) => {
    const TAG = "[apis/getMemberList]";
    let pageNo = req.query?.pageNo;
    if (!pageNo) {
        pageNo = 1;
    }
    let url = `${process.env.REACT_APP_API_URL}${CONSTANT.URI.ADMIN.MEMBER_LIST}?listType=2&orderType=${req.query.orderType}&pageNo=${pageNo}`;
    if (req.query.searchValue) {
        const searchValue = encodeURI(req.query.searchValue);
        url += `&searchValue=${searchValue}`;
    }
    console.log(TAG, `url: ${url} token:${getToken()}`, req);

    let rs = null;
    try {
        rs = await axios({
            method: "get",
            url: url,
            headers: { Authorization: `Bearer ${getToken()}` },
        });
    } catch (err) {
        console.log(TAG, "error", err);
    }
    if (rs.data?.result) {
        rs = rs.data?.result;
        if (rs.status?.code === '4100') { //	access token is expired
            const rt = getRefreshToken();
            const rs2 = await apis.putLogin({ refreshToken: rt });
            xlog(TAG, "r2", rs2);
            if (rs.data?.accessToken) {
                setCookie(CONSTANT.AUTH_TOKEN, rs.data.accessToken);
                setCookie(CONSTANT.AUTH_REFRESH_TOKEN, rs.data.refreshToken);
            } else {
                logout();
            }
        } else if (rs.status?.code === '4102' || rs.status?.code === '4002') { // 	refresh token is expired
            logout();
        }
    }
    return rs;
};
