import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { Button, Form } from 'react-bootstrap';

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";

import { xlog } from "../libs/utils";

const useLoginComponent = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userId, setUserId] = useState("");
    const [userPwd, setUserPwd] = useState("");
    return { global, dispatch, navigate, userId, setUserId, userPwd, setUserPwd };
};

const LoginComponent = ({ login }) => {
    const TAG = "LoginComponent";
    const { global, dispatch, navigate, userId, setUserId, userPwd, setUserPwd } = useLoginComponent();

    const handleLogin = () => async () => {
        xlog(TAG, `[handleLogin] ${global.isLogin}`);
        if (userId.length < 1) {
            window.alert("아이디를 입력해 주세요.");
            return;
        }
        if (userPwd.length < 1) {
            window.alert("비밀번호를 입력해 주세요.");
            return;
        }
        const rs = await login(userId, userPwd);
        xlog(TAG, `[handleLogin]rs`, rs);
    };

    const handleKeyboard = (type) => (evt) => {
        if (type === 1) {
            setUserId(evt.target.value.trim());
        } else if (type === 2) {
            setUserPwd(evt.target.value.trim());
        }
        xlog(TAG, `[handleKeyboard]type: ${type} ${evt.target.value} userId:${userId} userPwd:${userPwd}`);
    };

    return (
        <Container>
            <div css={cssVerticalCenter}>
                <div>로그인</div>
                <div css={css`display:flex;flex-direction: row;`}>
                    <div css={css`display:flex;flex-direction: column;padding: 10px;`}>
                        <div>
                            <Form.Control type="text" size="sm" placeholder="아이디를 입력해주세요." css={css`width:210px;padding:5px;`} onChange={handleKeyboard(1)} />
                        </div>
                        <div css={css`margin: 5px 0px 0px 0px;`}>
                            <Form.Control type="password" size="sm" placeholder="비밀번호를 입력해주세요." css={css`width:210px;padding:5px;`} onChange={handleKeyboard(2)} />
                        </div>
                    </div>
                    <div css={css`padding: 10px 0px 10px 10px;`}>
                        <Button variant="outline-dark" css={css`height:100%;`} onClick={handleLogin()}>log in</Button>
                    </div>
                </div>
                <div css={css`height:300px;`}>&nbsp;</div>
            </div>
        </Container >
    );

};
export default LoginComponent;
const cssVerticalCenter = css`
width:100%;
height: 100%;
#text-align:center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
#height: 200px;
# border: 3px solid green; 
`;
const Container = styled.div`
// height: 48px;
height:100%;
width: 100%;
# background: rgba(83, 83, 83, 0.99);

display: table;

#display: flex;
#flex-flow: row wrap;
# flex-wrap: wrap;
#justify-content: flex-start;
#align-items: stretch;
# 기본 최소 너비 지정
#flex-basis: 100px;
#flex-grow: 0;
`;