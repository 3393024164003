import axios from "axios";
import { xlog, setCookie, getToken, getRefreshToken, isLogin, logout } from "../../libs/utils";
import CONSTANT from "../../constants";
import apis from "./index";

/**
 * @param req.query.listType    5-매장관리>가입승인, 6-매장관리>매장회원리스트
 * @param req.query.orderType   listType=5 일때, orderType(1-최신순, 2-이름순, 3-특가메뉴건순, 4-찜사용률순) | listType=6 일때, orderType(1-최신순, 2-이름순, 3-찜예약건수순, 4-찜사용건수순)
 * @param req.query.status      listType=5 일때 필수, 1-승인대기, 2-승인완료, 3-승인거부
 */
export default async (req) => {
    const TAG = "[apis/getStoreList]";

    console.log(TAG, `req: ${req}`);

    let pageNo = req.query?.pageNo;
    if (!pageNo) {
        pageNo = 1;
    }
    let pageCnt = req.query?.pageCnt;
    if (!pageCnt) {
        pageCnt = 10;
    }
    let url = `${process.env.REACT_APP_API_URL}${CONSTANT.URI.ADMIN.STORE_LIST}?listType=${req.query.listType}&orderType=${req.query.orderType}&pageNo=${pageNo}&pageCnt=${pageCnt}`;
    if (req.query.status) {
        url += `&status=${req.query.status}`;
    }
    if (req.query.searchValue) { // listType=6, 매장명 검색
        const searchValue = encodeURI(req.query.searchValue);
        url += `&searchValue=${searchValue}`;
    }
    console.log(TAG, `url: ${url} token:${getToken()}`);

    let rs = null;
    try {
        rs = await axios({
            method: "get",
            url: url,
            headers: { Authorization: `Bearer ${getToken()}` },
        });
    } catch (err) {
        console.log(TAG, "error", err);
    }
    if (rs.data?.result) {
        rs = rs.data?.result;
        if (rs.status?.code === '4100') { //    access token is expired
            const rt = getRefreshToken();
            const rs2 = await apis.putLogin({ refreshToken: rt });
            xlog(TAG, "r2", rs2);
            if (rs.data?.accessToken) {
                setCookie(CONSTANT.AUTH_TOKEN, rs.data.accessToken);
                setCookie(CONSTANT.AUTH_REFRESH_TOKEN, rs.data.refreshToken);
            } else {
                logout();
            }
        } else if (rs.status?.code === '4102' || rs.status?.code === '4002') { //   refresh token is expired
            logout();
        }
    }
    return rs;
};
