import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "@redux/reducer";

import { xlog, isLogin, getCookie } from "../libs/utils";
import Shop from "@pages/shop";
import { setIsLogin } from "@redux/global";
import CONSTANT from "../constants";

const useIndex = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return { global, dispatch, navigate };
};

const Index = ({ }) => {
    const TAG = "[Index]";
    const { global, dispatch, navigate } = useIndex();
    // const localToken = localStorage.getItem("token");
    xlog(TAG, "index...", getCookie(CONSTANT.AUTH_TOKEN), "isLogin()", isLogin());
    useEffect(() => {
        // dispatch(setIsLogin(true)); // for test
        xlog(TAG, "[useEffect] index...", getCookie(CONSTANT.AUTH_TOKEN), "isLogin()", isLogin());

        if (!isLogin()) {
            xlog(TAG, "[useEffect] index...", getCookie(CONSTANT.AUTH_TOKEN), "isLogin()", isLogin());
            navigate("/login");
        }
    }, []);

    if (!isLogin()) {
        return (
            <></>
        );
    }
    return (
        <Shop />
    );
};
export default Index;
