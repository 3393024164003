import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import { xlog, getToken, isLogin, logout } from "../libs/utils";
import { Button } from 'react-bootstrap';
import { setIsLogin } from "@redux/global";
import api from '../pages/apis';
import CONSTANT from "../constants";

const useGNB = () => {
    const { global } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAlive, setIsAlive] = useState(false);
    return { global, dispatch, navigate, isAlive, setIsAlive };
};

const GNB = () => {
    const TAG = "GNB";
    const { global, dispatch, navigate, isAlive, setIsAlive } = useGNB();

    xlog(TAG, `isLogin: ${isLogin()}`);

    const handleLogout = () => () => {
        logout();
        navigate("/login");
    };

    const alive = async () => {
        const rs = await api.getAlive();
        xlog(TAG, "[alive]", rs.data.alive);
        if (rs.data?.alive === false) {
            logout();
            navigate("/login");
        } else {
            setIsAlive(true);
        }
    };

    useEffect(() => {
        xlog(TAG, `[useEffect] isLogin: ${isLogin()}, isAlive:${isAlive}`);
        if (isLogin()) {
            alive();
        } else {
            navigate("/login");
        }
    }, []);

    if (isAlive) {
        return <></>;
    }
    // alive();

    return (
        <Container>
            <div css={css`display: table-cell;vertical-align: middle;`}>
                <div css={css`margin-left: 10px;float: left;color: #FFFFFF;width: 121px;height: 21px;font-size: 18px;line-height: 21px;`}>마켓나우 관리자</div>
            </div>
            <div css={css`width:100%;`}></div>
            {/* <div css={css`display: table-cell;vertical-align: middle;text-align:right;`}>
                <div><Button variant="outline-dark" css={css`height:100%;margin-right: 10px;`} onClick={handleLogout()}>log out</Button></div>
            </div> */}
        </Container>
    );

};
export default GNB;

const Container = styled.div`
height: 48px;
background: rgba(83, 83, 83, 0.99);

display: table;

#display: flex;
#flex-flow: row wrap;
# flex-wrap: wrap;
#justify-content: flex-start;
#align-items: stretch;
# 기본 최소 너비 지정
#flex-basis: 100px;
#flex-grow: 0;
`;