import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import { Button, Pagination, Tabs, Tab, InputGroup, FormControl, Form, Table, Figure } from 'react-bootstrap';
import { xlog } from "../libs/utils";

const useBannerSaveComponent = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    const refUploadHero = useRef<any>();
    const refUploadDetail = useRef<any>();
    const refStartDate = useRef<any>();
    const refEndDate = useRef<any>();

    // const [heroImageURL, setHeroImageURL] = useState('dev/banner/59173c9e-a3c8-43fc-b337-b7fbbed15e79.jpeg');
    const [heroImageURL, setHeroImageURL] = useState(null);
    // const [detailImageURL, setDetailImageURL] = useState('dev/banner/76572529-02c5-4fc9-a354-9dc675fedc37.jpg');
    const [detailImageURL, setDetailImageURL] = useState(null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [product, setProduct] = useState(null);

    return { global, dispatch, navigate, location, params, refUploadHero, refUploadDetail, refStartDate, refEndDate, heroImageURL, setHeroImageURL, detailImageURL, setDetailImageURL, startDate, setStartDate, endDate, setEndDate, product, setProduct };
};

const BannerSaveComponent = ({ upload, saveBanner, bannerInfo, timesaleProductList, locationType }) => {
    const TAG = "BannerSaveComponent";
    const { global, dispatch, navigate, location, params, refUploadHero, refUploadDetail, refStartDate, refEndDate, heroImageURL, setHeroImageURL, detailImageURL, setDetailImageURL, startDate, setStartDate, endDate, setEndDate, product, setProduct } = useBannerSaveComponent();

    // xlog(TAG, location, params);
    xlog(TAG, "bannerInfo", bannerInfo, `locationType: ${locationType}`);

    const handleInputFile = (type) => (evt) => {
        xlog(TAG, `[handleInputFile] type: ${type}`);
        if (type === 1 && refUploadHero.current) {
            refUploadHero.current.click();
        } else if (type === 2 && refUploadDetail.current) {
            refUploadDetail.current.click();
        }
    };

    // https://d30n3cinyms2ie.cloudfront.net/dev/banner/59173c9e-a3c8-43fc-b337-b7fbbed15e79.jpeg
    const handleHeroFileChange = async (evt) => {
        xlog(TAG, `[handleHeroFileChange]`);
        const fileUploaded = evt.target.files[0];
        if (fileUploaded.size > 3145728) {
            alert('3M 이하의 파일만 업로드가 가능합니다');
        } else {
            const formData = new FormData();
            formData.append("file1", fileUploaded); // fileUploaded.size 3145728
            const rs = await upload(formData);
            xlog(TAG, "[handleHeroFileChange] rs", rs);
            if (rs.data?.path) {
                const url = rs.data?.path;
                // const url = process.env.REACT_APP_CDN_URL + rs.data?.path;
                setHeroImageURL(url);
            }
        }
    };

    const handleDetailFileChange = async (evt) => {
        xlog(TAG, `[handleDetailFileChange]`);
        const fileUploaded = evt.target.files[0];
        if (fileUploaded.size > 10485760) {
            alert('10M 이하의 파일만 업로드가 가능합니다');
        } else {
            const formData = new FormData();
            formData.append("file1", fileUploaded); // fileUploaded.size 3145728
            const rs = await upload(formData);
            xlog(TAG, "[handleDetailFileChange] rs", rs);
            if (rs.data?.path) {
                const url = rs.data?.path;
                // const url = process.env.REACT_APP_CDN_URL + rs.data?.path;
                setDetailImageURL(url);
                xlog(TAG, "[handleDetailFileChange] url", url);
            }
        }
    };

    const handleSaveBanner = () => (evt) => {
        xlog(TAG, `[handleSaveBanner] ${startDate} ${endDate} ${heroImageURL} ${detailImageURL}`);
        if (!startDate || startDate.length !== 10) {
            alert('게시 시작일을 YYYYMMDD 형식으로 입력해 주세요.\r\n예: 20220505');
            return;
        }
        if (!endDate || endDate.length !== 10) {
            alert('게시 종료일을 YYYYMMDD 형식으로 입력해 주세요.\r\n예: 20220505');
            return;
        }
        if (!heroImageURL) {
            alert('히어로 배너 이미지를 등록해 주세요.');
            return;
        }
        if (!detailImageURL) {
            alert('배너 상세보기 이미지를 등록해 주세요.');
            return;
        }
        // const s = startDate.substring(0, 4) + '-' + startDate.substring(4, 6) + '-' + startDate.substring(6, 8);
        // const e = endDate.substring(0, 4) + '-' + endDate.substring(4, 6) + '-' + endDate.substring(6, 8);;
        // xlog(TAG, `[handleSaveBanner] s:${s}, e:${e}`, bannerInfo);
        if (bannerInfo) {
            saveBanner(startDate, endDate, heroImageURL, detailImageURL, product, bannerInfo.bannerNo);
        } else {
            saveBanner(startDate, endDate, heroImageURL, detailImageURL, product);
        }
    };

    const handleKeyboard = (type) => (evt) => {
        xlog(TAG, `[handleKeyboard]type: ${type} ${evt.target.value} startDate:${startDate} endDate:${endDate} valid:${evt.target.validity.valid}`);
        if (!evt.target.validity.valid) {
            alert("숫자만 입력이 가능합니다.");
            evt.preventDefault();
            evt.stopPropagation();
            return;
        }
        if (type === 1) {
            setStartDate(evt.target.value.trim());
        } else if (type === 2) {
            setEndDate(evt.target.value.trim());
        }
    };

    /**
     * 특가 상품: Time Sale Product
     * @returns 
     */
    const handleProduct = () => (evt) => {
        xlog(TAG, `[handleProduct] ${evt.target.value}`);
        setProduct(evt.target.value);
    };

    const getDate = (t) => {
        xlog(TAG, `[getDate] ${t} ${refStartDate.current.startDate}`);
        let d = '';
        if (t === 1) {
            d = refStartDate.current.startDate;
        } else {
            d = refEndDate.current.endDate;
        }
        if (d) {
            const a = d.split('T');
            if (a && a[0]) {
                return a[0];
                // const b = a[0].split('-');
                // if (b && b.length > 2) {
                //     return `${b[0]}${b[1]}${b[2]}`;
                // }
            }
        }
        return d;
    };

    useEffect(() => {
        if (bannerInfo) {
            if (refStartDate.current) {
                xlog(TAG, "[useEffect/1]", bannerInfo?.startDate);
                refStartDate.current.startDate = bannerInfo?.startDate;
                refStartDate.current.value = getDate(1);
                setStartDate(refStartDate.current.value);
            }
            if (refEndDate.current) {
                xlog(TAG, "[useEffect/2]", bannerInfo?.endDate);
                refEndDate.current.endDate = bannerInfo?.endDate;
                refEndDate.current.value = getDate(2);
                setEndDate(refEndDate.current.value);
            }
            const a = bannerInfo?.mainImgPath.split('https://d30n3cinyms2ie.cloudfront.net/');
            setHeroImageURL(a[a.length - 1]);
            const b = bannerInfo?.subImgPath.split('https://d30n3cinyms2ie.cloudfront.net/');
            setDetailImageURL(b[b.length - 1]);
            if (bannerInfo?.timesaleProductNo) {
                setProduct(bannerInfo?.timesaleProductNo);
            }
        }
    }, [bannerInfo]);

    /*
    bannerNo: "14"
    endDate: "2022-04-19T00:00:00.000Z"
    mainImgPath: "https://d30n3cinyms2ie.cloudfront.net/dev/banner/01cfdadf-76b5-4225-8e11-e39875524fc6.jpeg"
    startDate: "2022-04-18T00:00:00.000Z"
    subImgPath: "https://d30n3cinyms2ie.cloudfront.net/dev/ba     
     */
    return (
        <Container>
            <div css={css`background-color:#FFFFFF;width:100%;height:62px;padding:20px;`}><b>배너 편집</b></div>
            <div css={css`background-color:#C4C4C4;width:100%;height:calc(100% - 62px);padding:10px;overflow:auto;`}>
                <Link to="/banner">&lt; 리스트로 돌아가기</Link>
                <div css={css`background-color:#FFFFFF;min-width:722px;min-height:492px;margin: 10px 0px 0px 0px;padding:10px;display:flex;flex-flow: row;`}>
                    <div css={css`width:50%;`}>히어로 배너<br /><br />
                        <div css={css`width:216px;min-height:186px;background-color:#C4C4C4;`}>
                            <Figure>
                                <Figure.Image
                                    width={216}
                                    height={186}
                                    src={heroImageURL ? process.env.REACT_APP_CDN_URL + heroImageURL : bannerInfo?.mainImgPath}
                                // src="https://d30n3cinyms2ie.cloudfront.net/dev/banner/59173c9e-a3c8-43fc-b337-b7fbbed15e79.jpeg"
                                />
                            </Figure>
                        </div>
                        <div css={css`margin: 10px 0px 10px 0px;`}>
                            <Button variant="danger" css={css`width: 200px;`} onClick={handleInputFile(1)}>이미지 추가</Button>
                            {/* , refUploadHero, refUploadDetail */}
                            <input type="file" accept='image/*' ref={refUploadHero} id="input-file-detail" onChange={handleHeroFileChange} css={css`display:none;`} />
                        </div>
                        {locationType && locationType === '1' ? '** 720*620 최적화되어 있습니다.' : '** 660*180 최적화되어 있습니다.'}
                        <br />
                        ** 3M 이하의 JPG 또는 PNG 파일만 업로드가 가능합니다.<br /><br />
                        게시일자
                        {/* 서브배너: 660*180 */}
                        <div css={css`display:flex;flex-flow: row;margin: 10px 0px 0px 0px;`}>
                            <Form.Control
                                type="date"
                                id="startDate"
                                aria-describedby="startDate"
                                placeholder="YYYY-MM-DD"
                                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                ref={refStartDate}
                                onChange={handleKeyboard(1)}
                                css={css`width:200px;`}
                                max="9999-12-31"
                                required
                            // title="Enter a date in this formart YYYY-MM-DD"
                            /> &nbsp;부터
                        </div>
                        <div css={css`display:flex;flex-flow: row;margin: 10px 0px 0px 0px;`}>
                            <Form.Control
                                type="date"
                                id="endDate"
                                aria-describedby="endDate"
                                placeholder="YYYY-MM-DD"
                                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                ref={refEndDate}
                                // value={bannerInfo && getDate(bannerInfo?.endDate)}
                                onChange={handleKeyboard(2)}
                                max="9999-12-31"
                                css={css`width:200px;`}
                                required
                            /> &nbsp;까지
                        </div>
                        특가상품
                        <div css={css`display:flex;flex-flow: row;margin: 10px 0px 0px 0px;`}>
                            <Form.Select aria-label="Default select example" css={css`width: 200px;`} onChange={handleProduct()}>
                                <option value=''>없음</option>
                                {timesaleProductList && timesaleProductList.map((v, i) => {
                                    let sel = false;
                                    if (v.timesaleProductNo === product) {
                                        sel = true;
                                    }
                                    return (
                                        <option key={i} value={v.timesaleProductNo} selected={sel}>[{v.storeName}] {v.productName}</option>
                                    );
                                })}
                            </Form.Select>
                        </div>
                        <div css={css`height:100px;margin: 20px 0px 0px 0px;`}>
                            <Button variant="dark" css={css`width:200px;height:60px;`} onClick={handleSaveBanner()}>배너 등록</Button>
                        </div>
                    </div>
                    <div css={css`width:50%;`}>배너 상세보기<br /><br />
                        <div css={css`width:216px;min-height:400px;background-color:#C4C4C4;`}>
                            <Figure>
                                <Figure.Image
                                    width={216}
                                    // height={612}
                                    src={detailImageURL ? process.env.REACT_APP_CDN_URL + detailImageURL : bannerInfo?.subImgPath}
                                // src="https://d30n3cinyms2ie.cloudfront.net/dev/banner/76572529-02c5-4fc9-a354-9dc675fedc37.jpg"
                                />
                            </Figure>
                        </div>
                        <div css={css`margin: 10px 0px 10px 0px;`}>
                            <Button variant="danger" css={css`width: 200px;`} onClick={handleInputFile(2)}>이미지 추가</Button>
                            <input type="file" accept='image/*' ref={refUploadDetail} id="input-file-detail" onChange={handleDetailFileChange} css={css`display:none;`} />
                        </div>
                        {locationType && locationType === '1' ? '** 가로사이즈 720 최적화되어 있습니다.' : '** 가로사이즈 660 최적화되어 있습니다.'}
                        {/* ** 가로사이즈 720 최적화되어 있습니다.<br /> */}
                        <br />
                        ** 10M 이하의 JPG 또는 PNG 파일만 업로드가 가능합니다.
                    </div>
                </div>
            </div>

        </Container >
    );
};
export default BannerSaveComponent;

const Container = styled.div`
height: 100%;
overflow:scroll;
# background: rgba(83, 83, 83, 0.99);

display: table;

#display: flex;
#flex-flow: row wrap;
# flex-wrap: wrap;
#justify-content: flex-start;
#align-items: stretch;
# 기본 최소 너비 지정
#flex-basis: 100px;
#flex-grow: 0;
`;