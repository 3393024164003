import getAlive from "./getAlive";
import getBannerInfo from "./getBannerInfo";
import getBannerList from "./getBannerList";
import getMemberList from "./getMemberList";
import getStoreList from "./getStoreList";

import getTimeSaleProductList from "./getTimeSaleProductList";

import postLogin from "./postLogin";
import postBanner from "./postBanner";
import postUpload from "./postUpload";

import putBannerInfo from "./putBannerInfo";
import putLogin from "./putLogin";
import putStoreStatus from "./putStoreStatus";

export default { getAlive, getBannerInfo, getBannerList, getMemberList, getStoreList, getTimeSaleProductList, postBanner, postLogin, putBannerInfo, putLogin, putStoreStatus, postUpload };