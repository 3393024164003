import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import { xlog } from "../libs/utils";

const LNB = () => {
    const TAG = "LNB";
    const { global } = useSelector((state: RootState) => state);
    xlog(TAG, `global.isLogin: ${global.isLogin}`);
    if (!global.isLogin) {
        return <></>;
    }

    let menuStr = "";
    menuStr = "매장 관리";
    return <Container>
        <div css={css`display: table-cell;vertical-align: middle;`}>
            <div css={css`margin-left: 10px;float: left;color: #000000;height: 23px;font-size: 20px;line-height: 23px;`}>{menuStr}</div>
        </div>
    </Container>;

};
export default LNB;

const Container = styled.div`
height: 62px;
background: #FFFFFF;
border: 1px solid #E5E5E5;
box-sizing: border-box;

display: table;

# display: flex;
# flex-wrap: wrap;
# justify-content: flex-start;
# align-items: stretch;
# 기본 최소 너비 지정
# flex-basis: 100px;
# flex-grow: 0;
# flex-shrink: 0;
`;