import axios from "axios";
import { Cookies } from "react-cookie";
import CONSTANT from "../constants";

/**
 *
 * @param {*} TAG string
 * @param {*} args object
 */
export function xlog(TAG, ...args) {
  console.log(TAG, args);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function deepCopyObject(inObject) {
  var outObject, value, key;
  if (typeof inObject !== "object" || inObject === null) {
    return inObject;
  }
  outObject = Array.isArray(inObject) ? [] : {};
  for (key in inObject) {
    value = inObject[key];
    outObject[key] =
      typeof value === "object" && value !== null
        ? deepCopyObject(value)
        : value;
  }
  return outObject;
}

export function axiosAPI(config = {}) {
  const token = localStorage.getItem(CONSTANT.AUTH_TOKEN);
  return axios({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...config,
  });
}

export const replaceUrl = (str) => {
  let strUrl = str
    .replace(/ /gi, "-")
    .replace(/ {2}/gi, "--")
    .replace(/\%/gi, "%25")
    .replace(/\:/gi, "%3A")
    .replace(/\'/gi, "%27")
    .replace(/\!/gi, "%21")
    .replace(/\"/gi, "%22")
    .replace(/\#/gi, "%23")
    .replace(/\$/gi, "%24")
    .replace(/\&/gi, "%26")
    .replace(/\(/gi, "%28")
    .replace(/\)/gi, "%29")
    .replace(/\+/gi, "%2B")
    .replace(/\\/gi, "%5C")
    .replace(/\,/gi, "%2C")
    .replace(/\./gi, "%2E")
    .replace(/\//gi, "%2F")
    .replace(/\:/gi, "%3A")
    .replace(/\;/gi, "%3B")
    .replace(/\;/gi, "%3B")
    .replace(/\</gi, "%3C")
    .replace(/\>/gi, "%3E")
    .replace(/\=/gi, "%3D")
    .replace(/\?/gi, "%3F")
    .replace(/\@/gi, "%40")
    .replace(/\{/gi, "%7B")
    .replace(/\}/gi, "%7D")
    .replace(/\[/gi, "%5B")
    .replace(/\]/gi, "%5D")
    .replace(/\`/gi, "%60")
    .replace(/\|/gi, "%7C")
    .replace(/\~/gi, "%7E");
  return strUrl;
};

const cookies = new Cookies();
export const setCookie = (name: string, value: string, options?: any) => {
  let opt: any = { path: "/", expires: 0 };
  // let opt: any = { path: "/", sameSite: "none" };

  if (options) {
    opt = options;
  }
  // console.log(`[setCookie] name: ${name} value: ${value}`, opt);
  return cookies.set(name, value, { ...opt });
};

export const removeCookie = (name: string, options?: any) => {
  // console.log(`[removeCookie] name:${name}`);
  cookies.remove(name);
};

export const getCookie = (name: string) => {
  // console.log(`[getCookie] name:${name} ${cookies.get(name)}`, cookies);
  return cookies.get(name);
};

export const isLogin = () => {
  // console.log(`[isLogin] ${getCookie(CONSTANT.AUTH_TOKEN)}`);
  return getCookie(CONSTANT.AUTH_TOKEN);
};

export const getToken = () => {
  return getCookie(CONSTANT.AUTH_TOKEN);
};

export const getRefreshToken = () => {
  return getCookie(CONSTANT.AUTH_REFRESH_TOKEN);
};

export const logout = () => {
  cookies.remove(CONSTANT.AUTH_TOKEN);
  cookies.remove(CONSTANT.AUTH_REFRESH_TOKEN);
};