import axios from "axios";
import { getToken } from "../../libs/utils";
import CONSTANTS from "../../constants";

/**
 * @param   refreshToken    AH2EjteBXyoZR2TrJK_C2SKvwoG0dsw5WsQDkGOODXA_
 * req
    {
        "refreshToken": "AH2EjteBXyoZR2TrJK_C2SKvwoG0dsw5WsQDkGOODXA_"
    }
 */
export default async (req) => {
    const TAG = "[apis/putLogin]";
    const url = process.env.REACT_APP_API_URL + CONSTANTS.URI.ADMIN.LOGIN;
    console.log(TAG, `url: ${url} req:`, req);

    let rs = null;
    try {
        rs = await axios({
            method: "put",
            url: url,
            // headers: { Authorization: `Bearer ${getToken()}` },
            data: req,
        });
    } catch (err) {
        console.log(TAG, "error", err);
        // rs = error;
    }
    if (rs.data?.result) {
        return rs.data.result;
    }
    return rs;
};
