import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";

import BannerSaveComponent from "@components/bannerSave";
import { xlog } from "../libs/utils";
import apis from "./apis";

const useBannerSave = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const [bannerId, setBannerId] = useState<string>();
    const [bannerInfo, setBannerInfo] = useState<any>(null);
    const [locationType, setLocationType] = useState<string>("1"); // 1-메인히어로, 2-메인서브
    const [timesaleProductList, setTimesaleProductList] = useState(null);
    return { global, dispatch, navigate, location, params, bannerId, setBannerId, locationType, setLocationType, bannerInfo, setBannerInfo, timesaleProductList, setTimesaleProductList };
};

const BannerSave = () => {
    const TAG = "BannerSave";
    const { global, dispatch, navigate, location, params, bannerId, setBannerId, locationType, setLocationType, bannerInfo, setBannerInfo, timesaleProductList, setTimesaleProductList } = useBannerSave();
    xlog(TAG, location, params, "REACT_APP_CDN_URL", process.env.REACT_APP_CDN_URL);
    /*
{
  "locationType": "1",
  "mainImgPath": "/files/img1.png",
  "subImgPath": "/files/img1.png",
  "startDate": "2022-03-10",
  "endDate": "2022-03-10"
}    
    */

    const upload = async (formData) => {
        xlog(TAG, "[upload]");
        if (formData) {
            const rs = await apis.postUpload(formData);
            xlog(TAG, "[upload]rs", rs, "rs.data?.path", rs.data?.path);
            return rs;
        }
    };

    /**
     * 
     * @param startDate 게시 시작일
     * @param endDate 게시 종료일
     * @param heroImageURL 히어로 이미지 URL
     * @param detailImageURL 상세 이미지 URL
     * @param bannerNo 배너 PK (수정일 경우에만)
     */
    const saveBanner = async (startDate, endDate, heroImageURL, detailImageURL, timesaleProductNo, bannerNo = null) => {
        const formData = new FormData();
        formData.append("locationType", locationType);
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);
        formData.append("mainImgPath", heroImageURL);
        formData.append("subImgPath", detailImageURL);
        if (timesaleProductNo) {
            formData.append("timesaleProductNo", timesaleProductNo);
        }
        xlog(TAG, "[saveBanner]", formData);

        let rs = null;
        if (bannerNo) {
            formData.append("bannerNo", bannerNo);
            rs = await apis.putBannerInfo(formData);
        } else {
            rs = await apis.postBanner(formData);
        }
        xlog(TAG, "[saveBanner]rs", rs);
        if (rs && rs.status?.code === '1000') {
            alert("정상적으로 저장 되었습니다.");
        } else {
            alert("저장에 실패하였습니다. 입력 데이터를 확인해 주세요.");
        }
        navigate('/banner');
    };

    const getBannerInfo = async (bannerNo) => {
        xlog(TAG, "[getBannerInfo]bannerNo", bannerNo);
        const req: any = { query: { bannerNo: bannerNo } };
        const rs = await apis.getBannerInfo(req);
        xlog(TAG, "[getBannerInfo]rs", rs);
        if (rs && rs.data && rs.data?.bannerInfo) {
            xlog(TAG, "[getBannerInfo]rs", rs.data);
            setBannerInfo(rs.data?.bannerInfo);
        } else {
            alert("배너 정보를 불러올 수 없습니다.");
            navigate('/banner');
        }
    };

    const getTimeSaleProductList = async () => {
        //getTimeSaleProductList
        const req: any = { query: {} };
        const rs = await apis.getTimeSaleProductList(req);
        xlog(TAG, "[getTimeSaleProductList]rs", rs);
        if (rs && rs.data && rs.data?.timesaleProductList) {
            setTimesaleProductList(rs.data?.timesaleProductList);
            /*
            bookingCnt: "0"
            discountPrice: "1000"
            discountRate: "22"
            endTime: "undefined"
            latitude: "37.527329"
            longitude: "126.767667"
            memo: ""
            photoImgPath: "https://d30n3cinyms2ie.cloudfront.net/dev/product/ad6c73ac-68fa-4e34-90d3-b2b7cd7fdb8c.png"
            productName: "ㄱㄴㄷㅈㅅㅂ"
            productNo: "16"
            remainCnt: "3"
            status: "2"
            storeName: "위액트"
            storeNo: "9"
            timesaleCnt: "3"
            timesalePrice: "1560"
            timesaleProductNo: "141"
            */
        };
    };

    useEffect(() => {
        xlog(TAG, `[useEffect] locationType: ${params.locationType}`);
        setLocationType(params.locationType?.trim());
        if (params && params.bannerId?.trim()) {
            xlog(TAG, `[useEffect] modify bannerId: ${params.bannerId}`);
            setBannerId(params.bannerId.trim());
            getBannerInfo(params.bannerId.trim());
        } else {
            xlog(TAG, "[useEffect] new");
        }
        getTimeSaleProductList();
    }, []);

    // useEffect(() => {
    //     getBannerInfo(bannerId);
    // }, [bannerId]);

    return (
        <BannerSaveComponent upload={upload} saveBanner={saveBanner} bannerInfo={bannerInfo} timesaleProductList={timesaleProductList} locationType={locationType} />
    );

};
export default BannerSave;