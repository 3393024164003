import axios from "axios";
import { getToken } from "../../libs/utils";
import CONSTANT from "../../constants";

export default async () => {
    const TAG = "[apis/getAlive]";
    const url = process.env.REACT_APP_API_URL + CONSTANT.URI.ADMIN.ALIVE;
    console.log(TAG, `url: ${url} token:${getToken()}`);

    let rs = null;
    try {
        rs = await axios({
            method: "get",
            url: url,
            headers: { Authorization: `Bearer ${getToken()}` },
        });
    } catch (err) {
        console.log(TAG, "error", err);
        // rs = error;
    }
    if (rs.data?.result) {
        return rs.data.result;
    }
    return rs;
};
