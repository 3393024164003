import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import moment from 'moment';
import { Button, Pagination, Tabs, Tab, InputGroup, FormControl, Form, Table } from 'react-bootstrap';
import { xlog } from "../libs/utils";

const useMemberComponent = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [startPageNo, setStartPageNo] = useState(1);
    const [paginationItems, setPaginationItems] = useState([]);
    const [tabkey, setTabKey] = useState('userList');
    const [memberCount, setMemberCount] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState();
    const [orderType, setOrderType] = useState(1);
    return { global, dispatch, navigate, currentPageNo, setCurrentPageNo, startPageNo, setStartPageNo, paginationItems, setPaginationItems, tabkey, setTabKey, memberCount, setMemberCount, searchKeyword, setSearchKeyword, orderType, setOrderType };
};

const MemberComponent = ({ getMemberList, memberList, totalCnt }) => {
    const TAG = "MemberComponent";
    const { global, dispatch, navigate, currentPageNo, setCurrentPageNo, startPageNo, setStartPageNo, paginationItems, setPaginationItems, tabkey, setTabKey, memberCount, setMemberCount, searchKeyword, setSearchKeyword, orderType, setOrderType } = useMemberComponent();

    const handleKeyboard = () => (evt) => {
        setSearchKeyword(evt.target.value.trim());
        xlog(TAG, `[handleKeyboard] ${evt.target.value}`);
    };

    const handleSearch = () => (evt) => {
        xlog(TAG, `[handleSearch] searchKeyword: ${searchKeyword} orderType: ${orderType}`);
        setCurrentPageNo(1);
        getMemberList(orderType, 1, searchKeyword);
    };

    const handleOrderType = () => (evt) => {
        xlog(TAG, `[handleOrderType] ${evt.target.value}`);
        if (evt.target.value !== orderType) {
            setOrderType(evt.target.value);
            setCurrentPageNo(1);
        }
    };

    const handleGoPage = (pageNo) => (evt) => {
        xlog(TAG, `[handleGoPage] ${pageNo}`);
        if (pageNo != currentPageNo) {
            setCurrentPageNo(pageNo);
        }
    };

    const handleNextPage = () => (evt) => {
        xlog(TAG, `[handleNextPage] ${currentPageNo}`);
        const totalPages = Math.ceil(totalCnt / 10);
        if (currentPageNo < totalPages) {
            setCurrentPageNo((currentPageNo + 1));
        }
    };

    const handlePrevPage = () => (evt) => {
        xlog(TAG, `[handleNextPage] ${currentPageNo}`);
        if (currentPageNo > 1) {
            setCurrentPageNo((currentPageNo - 1));
        }
    };

    useEffect(() => {
        xlog(TAG, `[useEffect/orderType] ${orderType}`);
        getMemberList(orderType, 1, searchKeyword);
    }, [orderType]);

    useEffect(() => {
        xlog(TAG, `[useEffect/currentPageNo] ${currentPageNo}`);
        getMemberList(orderType, currentPageNo, searchKeyword);
    }, [currentPageNo]);

    useEffect(() => {
        paginationItems.length = 0;
        const totalPages = Math.ceil(totalCnt / 10);
        for (let number = 1; number <= totalPages; number++) {
            paginationItems.push(
                <Pagination.Item key={number} active={number === currentPageNo} onClick={handleGoPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        xlog(TAG, "memberList", memberList, "getMemberList", getMemberList, `totalPages:${totalPages} paginationItems.length: ${paginationItems.length}`, paginationItems);
        setPaginationItems(paginationItems);
    }, [totalCnt, currentPageNo]);

    return (
        <Container>
            <div css={css`background-color:#FFFFFF;width:100%;height:62px;padding:20px;`}><b>사용자 관리</b></div>
            <div css={css`background-color:#C4C4C4;width:100%;height:calc(100% - 62px);padding:10px;overflow:auto;`}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={tabkey}
                    onSelect={(k) => setTabKey(k)}
                >
                    <Tab eventKey="userList" title="사용자회원 리스트">
                        <div css={css`background-color:#FFFFFF;min-width:722px;min-height:520px;padding:10px;display:flex;flex-flow: column;`}>
                            <div css={css`display:flex;flex-flow: row;justify-content: space-evenly;`}>
                                사용자 {totalCnt} 명 &nbsp;
                                <InputGroup css={css`width: 400px;`}>
                                    <FormControl
                                        placeholder="검색어를 입력해 주세요"
                                        aria-label="검색어를 입력해 주세요"
                                        aria-describedby="basic-addon2"
                                        onChange={handleKeyboard()}
                                    />
                                    <Button variant="outline-secondary" id="button-addon2" onClick={handleSearch()}>
                                        검색
                                    </Button>
                                </InputGroup>
                                <Form.Select aria-label="Default select example" css={css`width: 200px;`} onChange={handleOrderType()}>
                                    <option value="1">최신순</option>
                                    <option value="2">이름순</option>
                                    <option value="3">찜예약건수순</option>
                                    <option value="4">찜사용건수순</option>
                                </Form.Select>
                            </div>
                            <div css={css`margin: 10px 0px 0px 0px;`}>
                                <Table striped bordered hover size="md">
                                    <thead>
                                        <tr>
                                            <th>닉네임</th>
                                            <th>핸드폰번호</th>
                                            <th>회원유형</th>
                                            <th>가입일</th>
                                            <th>찜예약</th>
                                            <th>찜사용</th>
                                            <th>찜취소</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {memberList && memberList?.map((v, i) => {
                                            // xlog(TAG, `name: ${v.name}`, v);
                                            // const d = v.regDate;
                                            var d = moment.utc(v.regDate).local().format('YYYY-MM-DD'); // YYYY-MM-DD HH:mm:ss
                                            let m = "일반";
                                            // 1: 일반 2: 사장님 3: 매니저
                                            if (v.memberType === "2") {
                                                m = "사장님";
                                            } else if (v.memberType === "3") {
                                                m = "매니저";
                                            }
                                            return (
                                                <tr key={i}>
                                                    <td>{v.nickname}</td>
                                                    <td>{v.cellphoneNo}</td>
                                                    <td align="center">{m}</td>
                                                    <td align="center">{d}</td>
                                                    <td align="center">{v.bookingCnt} 회</td>
                                                    <td align="center">{v.useCnt} 회</td>
                                                    <td align="center">{v.cancelCnt} 회</td>
                                                </tr>
                                            );
                                        })}
                                        {/* <tr>
                                            <td>Mark</td>
                                            <td>010-1234-5678</td>
                                            <td>일반</td>
                                            <td>2022-04-02</td>
                                            <td>1 회</td>
                                            <td>0 회</td>
                                            <td>1 회</td>
                                        </tr> */}

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div css={css`margin: 10px;min-width:722px;min-height:32px;text-align:center;`}>
                            <div css={css`display: inline-block;`}>
                                <Pagination>
                                    {/* <Pagination.First /> */}
                                    <Pagination.Prev onClick={handlePrevPage()} />
                                    {paginationItems && paginationItems.map((v, i) => {
                                        return v;
                                    })}
                                    <Pagination.Next onClick={handleNextPage()} />
                                    {/* <Pagination.Last /> */}
                                </Pagination>
                            </div>
                        </div>
                    </Tab>
                </Tabs>

            </div>
        </Container>
    );

};
export default MemberComponent;

const Container = styled.div`
height: 100%;
overflow:scroll;
# background: rgba(83, 83, 83, 0.99);

#display: table;

display: flex;
flex-flow: column;
# flex-wrap: wrap;
justify-content: flex-start;
align-items: stretch;
# 기본 최소 너비 지정
flex-basis: 100px;
flex-grow: 0;
`;