import React from "react";
import { Route, Routes, useRoutes } from "react-router-dom";

import Index from "@pages/index";
import Shop from "@pages/shop";
import Member from "@pages/member";
import Banner from "@pages/banner";
import BannerSave from "@pages/bannerSave";
import Login from "@pages/login";

export const routes = [
    { path: "/", element: <Index /> },
    { path: "/login", element: <Login /> },

    { path: "/shop", element: <Shop /> },
    { path: "/shop/:pageNo", element: <Shop /> },

    { path: "/member", element: <Member /> },
    { path: "/member/:pageNo", element: <Member /> },

    { path: "/banner", element: <Banner /> },
    { path: "/banner/:pageNo", element: <Banner /> },

    { path: "/banner_save", element: <BannerSave /> },
    { path: "/banner_save/:locationType", element: <BannerSave /> },
    { path: "/banner_save/:locationType/:bannerId", element: <BannerSave /> },
];