import axios from "axios";
import { xlog, setCookie, getToken, getRefreshToken, isLogin, logout } from "../../libs/utils";
import CONSTANT from "../../constants";
import apis from "./index";

/**
 * @param   storeNo
 * @param   status      1 - 승인대기, 2 - 승인완료, 3 - 승인거부
 * @param   refuseMemo
 * req
{
  "storeNo": "1",
  "status": "3",
  "refuseMemo": "매장 연락처가 없습니다."
}
 */
export default async (req) => {
    const TAG = "[apis/putStoreStatus]";
    const url = process.env.REACT_APP_API_URL + CONSTANT.URI.ADMIN.STORE_STATUS;
    console.log(TAG, `url: ${url} req:`, req);

    let rs = null;
    try {
        rs = await axios({
            method: "put",
            url: url,
            headers: { Authorization: `Bearer ${getToken()}` },
            data: req,
        });
    } catch (err) {
        console.log(TAG, "error", err);
        // rs = error;
    }
    if (rs.data?.result) {
        rs = rs.data?.result;
        if (rs.status?.code === '4100') { //	access token is expired
            const rt = getRefreshToken();
            const rs2 = await apis.putLogin({ refreshToken: rt });
            xlog(TAG, "r2", rs2);
            if (rs.data?.accessToken) {
                setCookie(CONSTANT.AUTH_TOKEN, rs.data.accessToken);
                setCookie(CONSTANT.AUTH_REFRESH_TOKEN, rs.data.refreshToken);
            } else {
                logout();
            }
        } else if (rs.status?.code === '4102' || rs.status?.code === '4002') { // 	refresh token is expired
            logout();
        }
    }
    return rs;
};
