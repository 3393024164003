import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import moment from 'moment';
import { Button, Pagination, Tabs, Tab, InputGroup, FormControl, Form, Table } from 'react-bootstrap';
import { xlog } from "../libs/utils";

const useShopComponent = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [startPageNo, setStartPageNo] = useState(1);
    const [paginationItems, setPaginationItems] = useState([]);
    const [tabkey, setTabKey] = useState('5');
    const [storeStatus, setStoreStatus] = useState(1); // 1-승인대기, 2-승인완료, 3-승인거부
    const [searchKeyword, setSearchKeyword] = useState('');
    const [refuseMemo, setRefuseMemo] = useState('');
    const [orderType, setOrderType] = useState(1);
    const [changeStoreStatus, setChangeStoreStatus] = useState({ storeNo: -1, status: -1, refuseMemo: '' }); // 1-승인대기, 2-승인완료, 3-승인거부
    return { global, dispatch, navigate, currentPageNo, setCurrentPageNo, startPageNo, setStartPageNo, paginationItems, setPaginationItems, tabkey, setTabKey, storeStatus, setStoreStatus, orderType, setOrderType, searchKeyword, setSearchKeyword, changeStoreStatus, setChangeStoreStatus, refuseMemo, setRefuseMemo };
};

const ShopComponent = ({ putStoreStatus, getStoreList, storeList, totalCnt, pageCnt }) => {
    const TAG = "ShopComponent";
    const { global, dispatch, navigate, currentPageNo, setCurrentPageNo, startPageNo, setStartPageNo, paginationItems, setPaginationItems, tabkey, setTabKey, storeStatus, setStoreStatus, orderType, setOrderType, searchKeyword, setSearchKeyword, changeStoreStatus, setChangeStoreStatus, refuseMemo, setRefuseMemo } = useShopComponent();
    xlog(TAG, "storeList", storeList);

    const handleKeyboard = (inputType) => (evt) => {
        xlog(TAG, `[handleKeyboard] inputType:${inputType} ${evt.target.value.trim()}`);
        if (inputType === 'SEARCH') {
            setSearchKeyword(evt.target.value.trim());
        } else if (inputType === 'REFUSE_MEMO') {
            setRefuseMemo(evt.target.value.trim());
        }
    };

    const handleStoreStatus = (status) => (evt) => {
        if (status !== storeStatus) {
            setStoreStatus(status);
        }
    };

    /**
     * listType=5 일때, orderType(1-최신순, 2-이름순, 3-특가메뉴건순, 4-찜사용률순) 
     * listType=6 일때, orderType(1-최신순, 2-이름순, 3-찜예약건수순, 4-찜사용건수순)
     * @returns 
     */
    const handleOrderType = () => (evt) => {
        xlog(TAG, `[handleOrderType] ${evt.target.value}`);
        if (evt.target.value !== orderType) {
            setOrderType(evt.target.value);
        }
    };

    const handleSearch = () => (evt) => {
        setCurrentPageNo(1);
        const listType = parseInt(tabkey);
        xlog(TAG, `[handleSearch] searchKeyword: ${searchKeyword} orderType: ${orderType}`);
        getStoreList(listType, orderType, storeStatus, 1, pageCnt, searchKeyword);
    };

    const handleGoPage = (pageNo) => (evt) => {
        xlog(TAG, `[handleGoPage] ${pageNo}`);
        if (pageNo != currentPageNo) {
            setCurrentPageNo(pageNo);
        }
    };

    const handleNextPage = () => (evt) => {
        xlog(TAG, `[handleNextPage] ${currentPageNo}`);
        const totalPages = Math.ceil(totalCnt / pageCnt);
        if (currentPageNo < totalPages) {
            setCurrentPageNo((currentPageNo + 1));
        }
    };

    const handlePrevPage = () => (evt) => {
        xlog(TAG, `[handleNextPage] ${currentPageNo}`);
        if (currentPageNo > 1) {
            setCurrentPageNo((currentPageNo - 1));
        }
    };

    /**
     * 
     * @param storeNo 1 - 승인대기, 2 - 승인완료, 3 - 승인거부
     * @param status 
     * @returns 
     */
    const handleChangeStoreStatus = (storeNo, status) => (evt) => {
        xlog(TAG, `[handleChangeStoreStatus] storeNo: ${storeNo}, status: ${status}`);
        // { storeNo: -1, status: -1, refuseMemo: '' }
        setChangeStoreStatus({ storeNo: storeNo, status: status, refuseMemo: '' });
        if (refuseMemo) {
            setRefuseMemo('');
        }
    };

    const handleCancelChangeStoreStatus = () => (evt) => {
        xlog(TAG, `[handleCancelChangeStoreStatus]`);
        setChangeStoreStatus({ storeNo: -1, status: -1, refuseMemo: '' });
    };

    const handleChangeStoreStatusComplete = () => async (evt) => {
        xlog(TAG, `[handleChangeStoreStatusComplete]`);
        if (changeStoreStatus.status === 2) {
            await putStoreStatus(changeStoreStatus.storeNo, changeStoreStatus.status + '');
        } else {
            if (!refuseMemo) {
                alert('거부 사유를 작성해 주세요.');
                return;
            }
            await putStoreStatus(changeStoreStatus.storeNo, changeStoreStatus.status + '', refuseMemo);
        }
        handleCancelChangeStoreStatus()(null);
    };

    useEffect(() => {
        setCurrentPageNo(1);
        const listType = parseInt(tabkey);
        getStoreList(listType, orderType, storeStatus, 1, pageCnt, searchKeyword);
    }, [tabkey]);

    useEffect(() => {
        setCurrentPageNo(1);
        const listType = parseInt(tabkey);
        getStoreList(listType, orderType, storeStatus, 1, pageCnt, searchKeyword);
    }, [storeStatus]);

    useEffect(() => {
        xlog(TAG, `[useEffect/currentPageNo] ${currentPageNo}`);
        const listType = parseInt(tabkey);
        getStoreList(listType, orderType, storeStatus, currentPageNo, pageCnt, searchKeyword);
    }, [currentPageNo]);

    useEffect(() => {
        paginationItems.length = 0;
        const totalPages = Math.ceil(totalCnt / pageCnt);
        const newPaginationItems = [];
        for (let number = 1; number <= totalPages; number++) {
            newPaginationItems.push(
                <Pagination.Item key={number} active={number === currentPageNo} onClick={handleGoPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        setPaginationItems(newPaginationItems);
        xlog(TAG, `totalPages:${totalPages} paginationItems.length: ${newPaginationItems.length}`, newPaginationItems);
    }, [totalCnt, currentPageNo]);

    xlog(TAG, "changeStoreStatus", changeStoreStatus, paginationItems);
    return (
        <Container>
            <div css={css`background-color:#FFFFFF;width:100%;height:62px;padding:20px;`}><b>매장 관리</b></div>
            <div css={css`background-color:#C4C4C4;width:100%;height:calc(100% - 62px);padding:10px;overflow:auto;`}>

                {/* 가입 승인 팝업 */}
                {changeStoreStatus.storeNo > 0 ?
                    changeStoreStatus.status === 2 ?
                        <div css={css`display:flex;position:absolute;width:370px;height:237px;background-color:#E5E5E5;flex-wrap: wrap;align-items: center;justify-content: center;top:30%;left: 50%;transform: translate(-50%, -50%);`}>
                            <ul css={css`list-style-type: none;text-align: center;width:100%;height:100%;padding: 10px 10px 10px 10px;`}>
                                <li css={css`text-align: right;`}><Button variant="light" css={css`font-size:10px;`} onClick={handleCancelChangeStoreStatus()}>X</Button></li>
                                <li css={css`font-size:18px`}>[가입 승인]<br /><br /></li>
                                <li css={css`font-size:16px`}>해당 매장의 가입승인을 진행합니다.<br /><br /></li>
                                <li><Button variant="outline-danger" css={css`width:111px;`} onClick={handleChangeStoreStatusComplete()}>승인</Button></li>
                            </ul>
                        </div> :
                        <div css={css`display:flex;position:absolute;width:370px;height:257px;background-color:#E5E5E5;flex-wrap: wrap;align-items: center;justify-content: center;top:30%;left: 50%;transform: translate(-50%, -50%);`}>
                            <ul css={css`list-style-type: none;text-align: center;width:100%;height:100%;padding: 10px 10px 10px 10px;`}>
                                <li css={css`text-align: right;`}><Button variant="light" css={css`font-size:10px;`} onClick={handleCancelChangeStoreStatus()}>X</Button></li>
                                <li css={css`font-size:18px`}>[가입승인 거부]<br /><br /></li>
                                <li css={css`font-size:16px`}>해당 매장의 가입승인을 거부합니다.<br /></li>
                                <li><FormControl as="textarea" placeholder="거부사유를 적어주세요." aria-label="거부 사유" onChange={handleKeyboard('REFUSE_MEMO')} /><br /></li>
                                <li><Button variant="outline-dark" css={css`width:111px;`} onClick={handleChangeStoreStatusComplete()}>거부</Button></li>
                            </ul>
                        </div>
                    : <></>
                }

                <Tabs
                    id="controlled-tab-example"
                    activeKey={tabkey}
                    onSelect={(k) => setTabKey(k)}>
                    <Tab eventKey="5" title="가입승인">
                        <div css={css`height:calc(100% - 1px);background-color:#FFFFFF;min-width:722px;min-height:492px;padding:10px;`}>
                            <div css={css`display:flex;flex-flow: row;justify-content: flex-start;`}>
                                <Button variant={storeStatus == 1 ? "primary" : "light"} onClick={handleStoreStatus(1)}>승인대기</Button>&nbsp;
                                <Button variant={storeStatus == 2 ? "primary" : "light"} onClick={handleStoreStatus(2)}>승인완료</Button>&nbsp;
                                <Button variant={storeStatus == 3 ? "primary" : "light"} onClick={handleStoreStatus(3)}>승인거부</Button>&nbsp;
                            </div>
                            <div css={css`margin: 10px 0px 0px 0px;`}>
                                <Table striped bordered hover size="md">
                                    <thead>
                                        <tr>
                                            <th>매장명</th>
                                            <th>연락처</th>
                                            <th>사업자등록번호</th>
                                            <th>{storeStatus == 1 ? "승인" : (storeStatus === 2 ? "승인날짜" : "거부날짜 및 사유")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            storeList && storeList?.map((v, i) => {
                                                /*
approvalDate: "2022-04-14T08:58:46.000Z"
companyIdNo: "123-28-33130"
phoneNo: "02-123-4567"
refuseDate: ""
refuseMemo: ""
regDate: "2022-04-13T22:49:35.000Z"
storeName: "88당구장"
storeNo: "6"
timesaleProductCnt: "0"
useRate: "0"                                             
                                                 */
                                                // var d = moment.utc(v.regDate).local().format('YYYY-MM-DD'); // YYYY-MM-DD HH:mm:ss
                                                return (
                                                    <tr key={i}>
                                                        <td>{v.storeName}</td>
                                                        <td>{v.phoneNo}</td>
                                                        <td>{v.companyIdNo}</td>
                                                        <td>
                                                            {
                                                                storeStatus == 1 ? <><Button variant="danger" onClick={handleChangeStoreStatus(v.storeNo, 2)}>승인</Button>&nbsp;
                                                                    <Button variant="dark" onClick={handleChangeStoreStatus(v.storeNo, 3)}>거부</Button></> : (storeStatus === 2 ? v.approvalDate ? moment.utc(v.approvalDate).local().format('YYYY-MM-DD') : '' : `${moment.utc(v.refuseDate).local().format('YYYY-MM-DD')} ${v.refuseMemo}`)
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>

                        </div>
                        <div css={css`margin: 10px;min-width:722px;min-height:32px;text-align:center;`}>
                            <div css={css`display: inline-block;`}>
                                <Pagination>
                                    {/* <Pagination.First /> */}
                                    <Pagination.Prev onClick={handlePrevPage()} />
                                    {paginationItems && paginationItems.map((v, i) => {
                                        return v;
                                    })}
                                    <Pagination.Next onClick={handleNextPage()} />
                                    {/* <Pagination.Last /> */}
                                </Pagination>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="6" title="매장회원 리스트">
                        <div css={css`background-color:#FFFFFF;min-width:722px;min-height:492px;padding:10px;`}>
                            <div css={css`display:flex;flex-flow: row;justify-content: space-around;`}>
                                <div css={css`margin: 10px 0px 0px 0px;`}>총 매장 : {totalCnt}</div>
                                <InputGroup css={css`width: 400px;`}>
                                    <FormControl
                                        placeholder="검색어를 입력해 주세요"
                                        aria-label="검색어를 입력해 주세요"
                                        aria-describedby="basic-addon2"
                                        onChange={handleKeyboard('SEARCH')}
                                    />
                                    <Button variant="outline-secondary" id="button-addon2" onClick={handleSearch()}>
                                        검색
                                    </Button>
                                </InputGroup>
                                <Form.Select aria-label="Default select example" css={css`width: 200px;`} onChange={handleOrderType()}>
                                    <option value="1">최신순</option>
                                    <option value="2">이름순</option>
                                    <option value="3">특가메뉴건수순</option>
                                    <option value="4">찜사용률순</option>
                                </Form.Select>
                            </div>
                            <div css={css`margin: 10px 0px 0px 0px;`}>
                                <Table striped bordered hover size="md">
                                    <thead>
                                        <tr>
                                            <th>매장명</th>
                                            <th>연락처</th>
                                            <th>사업자등록번호</th>
                                            <th>가입일</th>
                                            <th>특가메뉴</th>
                                            <th>찜사용율</th>
                                            <th>비고</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* 
                                        approvalDate: "2022-04-14T08:58:46.000Z"
companyIdNo: "123-28-33130"
phoneNo: "02-123-4567"
refuseDate: ""
refuseMemo: ""
regDate: "2022-04-13T22:49:35.000Z"
storeName: "88당구장"
storeNo: "6"
timesaleProductCnt: "0"
useRate: "0"
                                        */}
                                        {/* <tr>
                                            <td>커피나우앤</td>
                                            <td>010-1234-5678</td>
                                            <td>111-11-23456</td>
                                            <td>2022-04-02</td>
                                            <td>12건</td>
                                            <td>45%</td>
                                        </tr> */
                                            storeList && storeList?.map((v, i) => {
                                                /*
    approvalDate: "2022-04-14T08:58:46.000Z"
    companyIdNo: "123-28-33130"
    phoneNo: "02-123-4567"
    refuseDate: ""
    refuseMemo: ""
    regDate: "2022-04-13T22:49:35.000Z"
    storeName: "88당구장"
    storeNo: "6"
    timesaleProductCnt: "0"
    useRate: "0"                                             
                                                 */
                                                var d = moment.utc(v.regDate).local().format('YYYY-MM-DD'); // YYYY-MM-DD HH:mm:ss
                                                return (
                                                    <tr key={i}>
                                                        <td align="center">{v.storeName}</td>
                                                        <td align="center">{v.phoneNo}</td>
                                                        <td align="center">{v.companyIdNo}</td>
                                                        <td align="center">{d}</td>
                                                        <td align="center">{v.timesaleProductCnt} 건</td>
                                                        <td align="center">{v.useRate} %</td>
                                                        <td align="center"><Button variant="danger" >삭제</Button></td>
                                                    </tr>
                                                );
                                            })
                                        }

                                    </tbody>
                                </Table>
                            </div>

                        </div>
                        <div css={css`margin: 10px;min-width:722px;min-height:32px;text-align:center;`}>
                            <div css={css`display: inline-block;`}>
                                <Pagination>
                                    {/* <Pagination.First /> */}
                                    <Pagination.Prev onClick={handlePrevPage()} />
                                    {paginationItems && paginationItems.map((v, i) => {
                                        console.log(v);
                                        return v;
                                    })}
                                    <Pagination.Next onClick={handleNextPage()} />
                                    {/* <Pagination.Last /> */}
                                </Pagination>
                            </div>
                        </div>
                    </Tab>
                </Tabs>

            </div>

        </Container >
    );

};
export default ShopComponent;

const Container = styled.div`
height: 100%;
overflow:scroll;
# background: rgba(83, 83, 83, 0.99);
background-color:#C4C4C4;
display: table;

#display: flex;
#flex-flow: row wrap;
# flex-wrap: wrap;
#justify-content: flex-start;
#align-items: stretch;
# 기본 최소 너비 지정
#flex-basis: 100px;
#flex-grow: 0;
`;