import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
// import { css } from '@emotion/react';
// import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import { xlog, setCookie, getToken, getRefreshToken, isLogin, logout } from "../libs/utils";
import CONSTANT from "../constants";
import apis from "./apis";

import MemberComponent from "@components/member";

const useMember = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [memberList, setMemberList] = useState([]);
    const [totalCnt, setTotalCnt] = useState(0);
    const [getMemberListRs, setGetMemberListRs] = useState({ memberList: [], totalCnt: 0 });
    return { global, dispatch, navigate, memberList, setMemberList, totalCnt, setTotalCnt, getMemberListRs, setGetMemberListRs };
};

const Member = () => {
    const TAG = "Member";
    const { global, dispatch, navigate, memberList, setMemberList, totalCnt, setTotalCnt, getMemberListRs, setGetMemberListRs } = useMember();
    xlog(TAG, "memberList", memberList);

    /**
     * 
     * @param orderType  1-최신순, 2-이름순, 3-찜예약건수순, 4-찜사용건수순
     */
    const getMemberList = async (orderType = 1, pageNo = 1, searchValue?) => {
        const req: any = { query: { orderType: orderType, pageNo: pageNo } };
        if (searchValue) {
            req.query.searchValue = searchValue;
        }
        const rs = await apis.getMemberList(req);
        if (rs && rs.data && rs.data.memberList) {
            xlog(TAG, "[getMemberList] rs", rs);
            setGetMemberListRs(rs.data);
        } else {
            setGetMemberListRs({ memberList: [], totalCnt: 0 });
        }
    };

    useEffect(() => {
        getMemberList();
    }, []);

    return (
        <MemberComponent getMemberList={getMemberList} memberList={getMemberListRs?.memberList} totalCnt={getMemberListRs?.totalCnt} />
    );

};
export default Member;