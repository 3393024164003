import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import { Button } from 'react-bootstrap';

import { xlog, isLogin, logout } from "../libs/utils";

const useSNB = () => {
    const { global } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    return { global, dispatch, navigate, location };
};

const SNB = () => {
    const TAG = "SNB";
    const { global, dispatch, navigate, location } = useSNB();
    xlog(TAG, `location: ${location.pathname}`);

    const handleLogout = () => () => {
        logout();
        navigate("/login");
    };

    if (!isLogin()) {
        return <></>;
    }
    let menuBg1 = "background: rgba(196, 196, 196, 0.5);";
    let menuBg2 = "";
    let menuBg3 = "";
    let menuBg4 = "";
    if (location.pathname === '/banner' || location.pathname.includes('/banner_save')) {
        menuBg1 = "";
        menuBg2 = "";
        menuBg3 = "background: rgba(196, 196, 196, 0.5);";
        menuBg4 = "";
    } else if (location.pathname === '/member') {
        menuBg1 = "";
        menuBg2 = "background: rgba(196, 196, 196, 0.5);";
        menuBg3 = "";
        menuBg4 = "";
    } 

    return (
        <Container>
            <div css={css`margin: 10px 18px 10px 16px;float: left;color: #ffffff;height: 26px;font-size: 22px;line-height: 26px;display: flex;align-items: center;justify-content:center;text-align: center;`}>마켓나우</div>
            <div css={css`display:flex;flex-flow: column wrap;margin: 0px 0px 0px 0px;`}>
                <Link to="/shop" css={css`${menuBg1} color: #ffffff;text-decoration: none;margin: 22px 0px 0px 0px;`}>
                    <Button variant="outline-dark" css={css`border:0px;width:100%;`}>
                        매장관리
                    </Button>
                </Link>
                <Link to="/member" css={css`${menuBg2} color: #ffffff;text-decoration: none;margin: 22px 0px 0px 0px;`}>
                    <Button variant="outline-dark" css={css`border:0px;width:100%;`}>
                        사용자관리
                    </Button>
                </Link>
                <Link to="/banner" css={css`${menuBg3} color: #ffffff;text-decoration: none;margin: 22px 0px 0px 0px;`}>
                    <Button variant="outline-dark" css={css`border:0px;width:100%;`}>
                        배너관리
                    </Button>
                </Link>
                <a href="https://www.ggulpick.com/event" target="_blank" css={css`${menuBg4} color: #ffffff;text-decoration: none;margin: 22px 0px 0px 0px;`}>
                    <Button variant="outline-dark" css={css`border:0px;width:100%;`}>
                        이벤트관리
                    </Button>
                </a>
            </div>
            <div css={css`position:relative;text-align:center;width:100%;height: calc(80% - 200px);margin: 400px 0px 0px 0px;vertical-align: bottom;`}>
                <div css={css`position:absolute;bottom:10px;width:100%;`}>
                    <Button variant="outline-warning" css={css`height:40px;width:90%;`} onClick={handleLogout()}>log out</Button>
                </div>
            </div>
        </Container >
    );

};
export default SNB;

const Container = styled.div`
width: 190px;
padding: 0 0 0 0;
// height: 100%;
background: rgba(83, 83, 83, 0.99);

display: flex;
flex-flow: column;
# flex-wrap: wrap;
justify-content: flex-start;
align-items: stretch;
# 기본 최소 높이 지정
#flex-basis: 30px;
#flex-grow: 0;
`;