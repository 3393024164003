/**
 * usage
 * import URI from './constants/uri';
 */
const prefix = 'api/v1/';
export default {
    COMMON: {
        UPLOAD: prefix + 'file/upload',
    },
    ADMIN: {
        LOGIN: prefix + 'admin/login',
        ALIVE: prefix + 'admin/alive',
        MEMBER_LIST: prefix + '/admin/member/list',
        STORE_LIST: prefix + '/admin/store/list',
        STORE_STATUS: prefix + '/admin/store/status',
        BANNER: prefix + '/admin/banner',
        BANNER_LIST: prefix + '/admin/banner/list',
        BANNER_INFO: prefix + '/admin/banner/info',
        TIMESALE_LIST: prefix + '/product/timesale/list',
    },
};