import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@redux/reducer";
import { nsCommon } from "type-common";
import moment from 'moment';
import { Button, Pagination, Tabs, Tab, InputGroup, FormControl, Form, Table, Figure } from 'react-bootstrap';
import { xlog } from "../libs/utils";

const useBannerComponent = () => {
    const { global } = useSelector((state: RootState) => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [startPageNo, setStartPageNo] = useState(1);
    const [paginationItems, setPaginationItems] = useState([]);
    const [tabkey, setTabKey] = useState('3');
    const [isActive, setIsActive] = useState(1);
    return { global, dispatch, navigate, currentPageNo, setCurrentPageNo, startPageNo, setStartPageNo, paginationItems, setPaginationItems, tabkey, setTabKey, isActive, setIsActive };
};

const BannerComponent = ({ getBannerList, bannerList, totalCnt }) => {
    const TAG = "BannerComponent";
    const { global, dispatch, navigate, currentPageNo, setCurrentPageNo, startPageNo, setStartPageNo, paginationItems, setPaginationItems, tabkey, setTabKey, isActive, setIsActive } = useBannerComponent();

    const handleGoPage = (pageNo) => (evt) => {
        xlog(TAG, `[handleGoPage] ${pageNo}`);
        if (pageNo != currentPageNo) {
            setCurrentPageNo(pageNo);
        }
    };

    const handleNextPage = () => (evt) => {
        xlog(TAG, `[handleNextPage] ${currentPageNo}`);
        const totalPages = Math.ceil(totalCnt / 10);
        if (currentPageNo < totalPages) {
            setCurrentPageNo((currentPageNo + 1));
        }
    };

    const handlePrevPage = () => (evt) => {
        xlog(TAG, `[handleNextPage] ${currentPageNo}`);
        if (currentPageNo > 1) {
            setCurrentPageNo((currentPageNo - 1));
        }
    };

    const handleToggleActive = ((val) => (evt) => {
        xlog(TAG, `[handleToggleActive] ${val}`);
        if (val !== isActive) {
            setIsActive(val);
        }
    });

    useEffect(() => {
        setCurrentPageNo(1);
        const listType = parseInt(tabkey);
        xlog(TAG, `[useEffect/tabkey] ${listType}`);
        getBannerList(listType, 1, 1);
    }, [tabkey]);

    useEffect(() => {
        setCurrentPageNo(1);
        const listType = parseInt(tabkey);
        xlog(TAG, `[useEffect/tabkey] ${listType}`);
        getBannerList(listType, isActive, 1);
    }, [isActive]);

    useEffect(() => {
        paginationItems.length = 0;
        const totalPages = Math.ceil(totalCnt / 10);
        for (let number = 1; number <= totalPages; number++) {
            paginationItems.push(
                <Pagination.Item key={number} active={number === currentPageNo} onClick={handleGoPage(number)}>
                    {number}
                </Pagination.Item>,
            );
        }
        xlog(TAG, `totalPages:${totalPages} paginationItems.length: ${paginationItems.length}`, paginationItems);
        setPaginationItems(paginationItems);
    }, [totalCnt, currentPageNo]);

    const handleGoSave = () => (evt) => {
        xlog(TAG, `[handleGoSave] tabkey: ${tabkey}`);
        if (tabkey === '3') {
            xlog(TAG, `[handleGoSave] main`);
            navigate('/banner_save/1/');
        } else if (tabkey === '4') {
            xlog(TAG, `[handleGoSave] sub`);
            navigate('/banner_save/2/');
        }
    };

    const handleGoView = (bannerId) => (evt) => {
        xlog(TAG, `[handleGoView] tabkey: ${tabkey}`);
        if (tabkey === '3') {
            xlog(TAG, `[handleGoSave] main`);
            navigate(`/banner_save/1/${bannerId}`);
        } else if (tabkey === '4') {
            xlog(TAG, `[handleGoSave] sub`);
            navigate(`/banner_save/2/${bannerId}`);
        }
    };
    /* bannerNo: "3"
    clickCnt: "2"
    endDate: "2022-03-24T21:03:32.000Z"
    exposureCnt: "0"
    mainImgPath: "https://d30n3cinyms2ie.cloudfront.net/dev/banner/a1f91fee-7619-47fb-aa84-d217066bf327.jpg"
    startDate: "2022-03-24T21:03:32.000Z"
    subImgPath: "https://d30n3cinyms2ie.cloudfront.net/dev/banner/a1f91fee-7619-47fb-aa84-d217066bf327.jpg" */
    let bannerListComponents = [<Figure css={css`margin-right: 10px;`} key={-1}>
        {/* <Link to="/banner_save"> */}
        <Button
            css={css`width:171px;height:180px;background-color:#C4C4C4;border:0px;`}
            onClick={handleGoSave()}
        >+</Button>
        {/* </Link> */}
    </Figure>];
    if (bannerList) {
        for (let i = 0; i < bannerList.length; i++) {
            xlog(TAG, ".....", bannerList[i].mainImgPath);
            bannerListComponents.push(
                <span key={i} css={css`margin-right: 10px;`}>
                    {(i !== 0 && (i + 1) % 5 === 0) ? <br /> : ''}
                    <Button variant="outline-dark" onClick={handleGoView(bannerList[i].bannerNo)} css={css`padding:0px;`}>
                        <Figure key={i} css={css`margin:1px;`}>
                            <Figure.Image
                                width={171}
                                height={180}
                                src={bannerList[i].mainImgPath}
                                css={css`margin:0px;`}
                            // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrTFrhr_-pYR74jUgOy7IerAoHAX3zPIZZcg&usqp=CAU"
                            />
                        </Figure>
                    </Button>
                </span>);
        }
    }
    xlog(TAG, 'bannerList', bannerList);

    return (
        <Container>
            <div css={css`background-color:#FFFFFF;width:100%;height:62px;padding:20px;`}><b>배너 관리</b></div>
            <div css={css`background-color:#C4C4C4;width:100%;height:calc(100% - 62px);padding:10px;overflow:auto;`}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={tabkey}
                    onSelect={(k) => setTabKey(k)}
                >
                    <Tab eventKey="3" title="메인 히어로 배너">
                        <div css={css`background-color:#FFFFFF;min-width:722px;min-height:492px;padding:10px;display:flex;flex-flow: column;`}>
                            <div css={css`margin:10px 0px 10px 0px;`}>
                                <Button variant={isActive === 1 ? "primary" : "light"} onClick={handleToggleActive(1)}>게시중 배너</Button>&nbsp;
                                <Button variant={isActive === 1 ? "light" : "primary"} onClick={handleToggleActive(2)}>지난 배너</Button>&nbsp;
                            </div>
                            {isActive === 1 ?
                                <>
                                    <div>
                                        {
                                            bannerListComponents && bannerListComponents.map((v, i) => {
                                                return (v);
                                            })
                                        }
                                    </div>
                                    <div>
                                        ** 최대 12개까지 업로드가 가능합니다. 12개가 넘을 경우 최근 등록한 순으로 12개가 보여집니다. <br />
                                        ** 10M 이하의 JPG 또는 PNG 파일만 업로드 가능합니다.
                                    </div>
                                </> :
                                <>
                                    <Table striped bordered hover size="md">
                                        <thead>
                                            <tr>
                                                <th css={css`text-align: center;`}>썸네일</th>
                                                <th css={css`text-align: center;`}>게시일자</th>
                                                <th css={css`text-align: center;`}>누적 노출</th>
                                                <th css={css`text-align: center;`}>누적 클릭수</th>
                                            </tr>
                                            {
                                                bannerList && bannerList.map((v, i) => {
                                                    /*
                                                    bannerNo: "3"
                                                    clickCnt: "2"
                                                    endDate: "2022-03-24T21:03:32.000Z"
                                                    exposureCnt: "0"
                                                    mainImgPath: "https://d30n3cinyms2ie.cloudfront.net/dev/banner/a1f91fee-7619-47fb-aa84-d217066bf327.jpg"
                                                    startDate: "2022-03-24T21:03:32.000Z"
                                                    subImgPath: "https://d30n3cinyms2ie.cloudfront
                                                    */
                                                    const s = moment.utc(v.startDate).local().format('YYYY-MM-DD'); // YYYY-MM-DD HH:mm:ss
                                                    const e = moment.utc(v.endDate).local().format('YYYY-MM-DD'); // YYYY-MM-DD HH:mm:ss

                                                    xlog(TAG, "s", s);
                                                    return (
                                                        <tr key={i}>
                                                            <td css={css`width:100px;`}>{bannerListComponents[i + 1]}</td>
                                                            <td css={css`text-align: center;`} valign="middle">{s} ~ {e}</td>
                                                            <td css={css`text-align: center;`} valign="middle">{v.exposureCnt} 회</td>
                                                            <td css={css`text-align: center;`} valign="middle">{v.clickCnt} 회</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </Table>
                                </>}
                        </div>
                        <div css={css`margin: 10px;min-width:722px;min-height:32px;text-align:center;`}>
                            <div css={css`display: inline-block;`}>
                                <Pagination>
                                    {/* <Pagination.First /> */}
                                    <Pagination.Prev onClick={handlePrevPage()} />
                                    {paginationItems && paginationItems.map((v, i) => {
                                        return v;
                                    })}
                                    <Pagination.Next onClick={handleNextPage()} />
                                    {/* <Pagination.Last /> */}
                                </Pagination>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="4" title="메인 서브 배너">
                        <div css={css`background-color:#FFFFFF;min-width:722px;min-height:492px;padding:10px;display:flex;flex-flow: column;`}>
                            <div css={css`margin:10px 0px 10px 0px;`}>
                                <Button variant={isActive === 1 ? "primary" : "light"} onClick={handleToggleActive(1)}>게시중 배너</Button>&nbsp;
                                <Button variant={isActive === 1 ? "light" : "primary"} onClick={handleToggleActive(2)}>지난 배너</Button>&nbsp;
                            </div>
                            {isActive === 1 ?
                                <>
                                    <div>
                                        {
                                            bannerListComponents && bannerListComponents.map((v, i) => {
                                                return (v);
                                            })
                                        }
                                    </div>
                                    <div>
                                        ** 최대 12개까지 업로드가 가능합니다. <br />
                                        ** 10M 이하의 JPG 또는 PNG 파일만 업로드 가능합니다.
                                    </div>
                                </> :
                                <>

                                    <Table striped bordered hover size="md">
                                        <thead>
                                            <tr>
                                                <th css={css`text-align: center;`}>썸네일</th>
                                                <th css={css`text-align: center;`}>게시일자</th>
                                                <th css={css`text-align: center;`}>누적 노출</th>
                                                <th css={css`text-align: center;`}>누적 클릭수</th>
                                            </tr>
                                            {
                                                bannerList && bannerList.map((v, i) => {
                                                    /*
                                                    bannerNo: "3"
                                                    clickCnt: "2"
                                                    endDate: "2022-03-24T21:03:32.000Z"
                                                    exposureCnt: "0"
                                                    mainImgPath: "https://d30n3cinyms2ie.cloudfront.net/dev/banner/a1f91fee-7619-47fb-aa84-d217066bf327.jpg"
                                                    startDate: "2022-03-24T21:03:32.000Z"
                                                    subImgPath: "https://d30n3cinyms2ie.cloudfront
                                                    */
                                                    const s = moment.utc(v.startDate).local().format('YYYY-MM-DD'); // YYYY-MM-DD HH:mm:ss
                                                    const e = moment.utc(v.endDate).local().format('YYYY-MM-DD'); // YYYY-MM-DD HH:mm:ss

                                                    xlog(TAG, "s", s);
                                                    return (
                                                        <tr key={i}>
                                                            <td css={css`width:100px;`}>{bannerListComponents[i + 1]}</td>
                                                            <td css={css`text-align: center;`} valign="middle">{s} ~ {e}</td>
                                                            <td css={css`text-align: center;`} valign="middle">{v.exposureCnt} 회</td>
                                                            <td css={css`text-align: center;`} valign="middle">{v.clickCnt} 회</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </Table>
                                </>}
                        </div>
                        <div css={css`margin: 10px;min-width:722px;min-height:32px;text-align:center;`}>
                            <div css={css`display: inline-block;`}>
                                <Pagination>
                                    {/* <Pagination.First /> */}
                                    <Pagination.Prev onClick={handlePrevPage()} />
                                    {paginationItems && paginationItems.map((v, i) => {
                                        return v;
                                    })}
                                    <Pagination.Next onClick={handleNextPage()} />
                                    {/* <Pagination.Last /> */}
                                </Pagination>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Container>
    );
};
export default BannerComponent;

const Container = styled.div`
                height: 100%;
                overflow:scroll;
                # background: rgba(83, 83, 83, 0.99);

                display: table;

                #display: flex;
                #flex-flow: row wrap;
                # flex-wrap: wrap;
                #justify-content: flex-start;
                #align-items: stretch;
                # 기본 최소 너비 지정
                #flex-basis: 100px;
                #flex-grow: 0;
                `;;